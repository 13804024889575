<template>

    <!-- login-area -->
    <section class="login-area">
        <div class="container-fluid">
            <div class="row">
                <div class="col-md-6 col-lg-7">
                    <div class="login-left row">
                        <div class="introduction">
                            <div class="heading wow fadeInLeft" data-wow-duration="1s" data-wow-delay=".5s">
                                <h1>Welcome To DealersGear CRM</h1>
                                <p>The Platform Built By Dealers For Dealers</p>
                            </div>
                            <div class="heading-img wow fadeInLeft" data-wow-duration="1s" data-wow-delay="1s">
                                <img class="img-fluid" src="../assets/img/Frame.svg" alt="image">
                            </div>
                        </div>
                    </div>
                </div>
                <div class="col-md-6 col-lg-5">
                    <div class="login-right row">
                        <div class="login-form custom-form">
                            <!-- logo -->
                            <div class="logo-login wow fadeInUp" data-wow-duration="1s" data-wow-delay="1s">
                                <a href="index.html"><img class="img-fulid" src="../assets/img/logo.svg" alt="logo"></a>
                            </div>
                            <!-- logo -->
                            <div class="login-title wow fadeInLeft" data-wow-duration="1s" data-wow-delay="1s">
                               
                            </div>
                            <form class="wow fadeInRight login_" data-wow-duration="1s" data-wow-delay="1s" v-on:submit.prevent = "forgotpassword" >
                                <div class="login-error-message text-center" v-if="authError"> User does not exist.</div>
                                <div class="login-error-message text-center" style="color: green;" v-if="successMsg"> Password reset link sent on your email.</div>
                                <div class="form-group label-design" v-on:click="inputLabelStyle($event)">
                                    <label class="label-class" for="inlineFormInputGroup">Email address</label>  
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                          <div class="input-group-text"><img class="img-fluid" src="../assets/img/Account.svg" alt=""></div>
                                        </div>
                                        <input type="email" class="form-control" @focus="inputLabelStyle($event,'parent')" id="inlineFormInputGroup" placeholder="" v-model="email">
                                    </div>
                                </div>

                                
                                
                                
                                <!-- <div class="form-group label-design" v-on:click="inputLabelStyle($event)">
                                    <label class="label-class" for="inlineFormInputGroup">Email address</label>  
                                    <div class="input-group">
                                        <div class="input-group-prepend">
                                          <div class="input-group-text"><img class="img-fluid" src="../assets/img/Account.svg" alt=""></div>
                                        </div>
                                        <input type="email" id="inlineFormInputGroup"  @focus="inputLabelStyle($event,'parent')" class="form-control" placeholder="user@example.com" v-model="email" required>
                                    </div>
                                </div> -->
                                
                               
                               

                                <button type="submit" class="login-btn" :disabled="btnLoader"><span v-if="btnLoader"><span class="spinner-grow spinner-grow-sm"></span> Loading...</span> <span v-if="!btnLoader">Submit</span> </button>
                                <!-- <p class="allready">You don’t have a crm? <a href="#">Signup</a></p> -->
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </section>
    <!-- login-area -->

</template>

<script>
import $ from 'jquery'
import axios from 'axios'

export default {
    name: 'Forgot',
    beforeCreate: function () {
        var auth = this.$storage.get('auth')
        if (auth) {
            this.$router.push('/dashboard')
        }
    },
	data(){
		return{
            token: null,
            email: null,
            authError: false,
            btnLoader:false,
            successMsg: false
		}
    },
	methods:{
        forgotpassword(){
            this.btnLoader = true
            axios
            .post('/send/reset/email',{email: this.email})
            .then( res => {
                this.email = '';
                console.log(res.data)

                if (res.data.status == true) {
                    this.successMsg = true;
                    this.authError = false;
                }else {
                    this.authError = true;
                    this.successMsg = false;
                }
                this.btnLoader = false
            })
            .catch(err =>{
                this.btnLoader = false
                console.log(err)
            });



            // axios.post('/send/reset/email', {
            //     email: this.email,
            // })
            // .then(response => {
            //     console.log(response.data)
            //     this.authError = false;
            //     this.successMsg = true;
            //     this.email = '';
            //     // if (response.data.status == true) {
            //     //     this.message = response.data.message
            //     // }
            //     // if (response.data.status == false) {
            //     //     this.$toast.open({
            //     //         type : 'error',
            //     //         message: response.data.message
            //     //     });

            //     // }
            // })
            // .catch(error => {
            //     this.authError = true;
            //     this.successMsg = false;
            //     this.btnLoader = false;
            // });
        },
        inputLabelStyle(event,type=null){
            if(type == 'parent'){
                $(event.currentTarget).parent().parent().addClass('input-border');
                $(event.currentTarget.parentElement.parentElement.querySelector('label')).addClass('label-top');
            }else{
                $(event.currentTarget).addClass('input-border');
                $(event.currentTarget.querySelector('label')).addClass('label-top');
            }
        }
    }
}
</script>